<template>
  <div>
    <h1 style="margin-left:0%;margin-bottom:4%">Organismes</h1>
    <ListeOrganismes></ListeOrganismes>
  </div>
</template>

<script>
import ListeOrganismes from "./ListeOrganismes";
export default {
  name: "OrganismesIndex",
  components: { ListeOrganismes }
};
</script>

<style scoped>
</style>
