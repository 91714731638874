<template>
  <div>
    <a-row type="flex" justify="space-around" class="table_head">
      <a-col :span="7">
        <a-button class="editable-add-btn" type="primary" @click="handleAdd"
          >Nouveau</a-button
        >
      </a-col>
      <a-col :span="6" style="margin-bottom: 2%">
        <search-component v-on:change="search($event)"></search-component>
      </a-col>
    </a-row>
    <CreateForm
            :key="visible"
            :organisme="organisme"
            :processing="processing"
            :type-attestations="typeAttestations"
            :visible="visible"
            @cancel="closeModal"
            @confirme="handleCreate"
            ref="createForm"
    />
    <a-row type="flex" justify="center">
      <a-col :span="18">
        <a-spin :spinning="loading">
          <a-table
                  :columns="columns"
                  :dataSource="organismes"
                  :rowKey="
              record => {
                return record.id;
              }
            "
                  bordered
                  v-if="organismes.length"
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                      v-if="organismes.length"
                      :title="SUPPRIMER_LIGNE"
                      @confirm="() => onDelete(record)"
              >
                <a-tooltip>
                  <a-icon
                    type="delete"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                  <template slot="title">Suppression</template>
                </a-tooltip>
              </a-popconfirm>
              <a-tooltip>
                <a-icon
                  type="edit"
                  @click="handleEdit(record)"
                  class="action_icons"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
                <template slot="title">Edition</template>
              </a-tooltip>
            </template>
            <!-- START Child Row -->
            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <br />
              <a-icon type="phone" style="margin-right : 10px" />
              <strong>Téléphone</strong>
              <br />Mobile :
              <strong>{{ record.tel_mobile }}</strong>
              <br />Fixe :
              <strong>{{ record.tel_fix }}</strong>
              <br />Fax :
              <strong>{{ record.tel_fax }}</strong>
              <br />
              <br />
              <a-icon type="mail" style="margin-right : 10px" />
              <strong>{{ record.email }}</strong>
              <br />
              <br />
              <span v-if="record.avantage">
                <strong>Avantages :</strong>
                <br />
                <br />
                <a-textarea
                  disabled
                  :value="record.avantage"
                  :rows="4"
                />
                <br />
              </span>
            </div>
            <!-- END Child Row -->
          </a-table>
          <EmptyResult v-else/>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import moment from "moment";

  import CreateForm from "./OrganismeEditor";
  import SearchComponent from "@/components/common/Search";
  import EmptyResult from "@/components/common/EmptyResult";
  import constants from "@/const/const";
  import debounce from "lodash-es/debounce";


  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"}
    },
    {
      title: "Code",
      dataIndex: "code"
    },
    {
      title: "Nom",
    dataIndex: "nom_fr"
  },
  {
    title: "Adresse",
    dataIndex: "adresse"
  },
  {
    title: "Tél. mobile",
    dataIndex: "tel_mobile"
  },
  {
    title: "Type Atte.",
    dataIndex: "type_attestation.nom_fr"
  },
  {
    title: "Action",
    scopedSlots: { customRender: "operation" }
  }
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0
    },
    sm: {
      span: 12,
      offset: 20
    }
  }
};
export default {
  name: "ListeOrganismes",
  created() {
    this.getAdditioanlData();
    this.getTypeAttestations();
  },
  components: {
    CreateForm,
    SearchComponent,
    EmptyResult
  },
  data() {
    this.search = debounce(this.search, 1000);
    return {
      confirmDirty: false,
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout,
      columns: columns,
      observation: "",
      visible: false,
      loading: true,
      processing: false,
      confirmLoading: false,
      SUPPRIMER_LIGNE: constants.SUPPRIMER_LIGNE
    };
  },
  computed: {
    ...mapState({
      organismes: state => state.organismes.organismes,
      organisme: state => state.organismes.selected_organisme,
      typeAttestations: state => state.types_attestations.type_attestations
    }),
    editMode() {
      return this.organisme;
    }
  },
  methods: {
    moment,
    getAdditioanlData(q = "") {
      this.startLoading();
      this.fetchOrganismes({q: q})
              .finally(() => this.stopLoading());
    },
    handleAdd() {
      this.showModal();
    },
    handleEdit(organisme) {
      this.setOrganisme(organisme);
      this.showModal();
    },
    search(e) {
      this.getAdditioanlData(e);
    },
    closeModal() {
      this.visible = false;
      this.clearOrganisme();
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    onDelete(organisme) {
      this.remove(organisme)
              .then(() => this.getAdditioanlData());
    },
    showModal() {
      this.visible = true;
    },
    cleanModal() {
      this.stopProcessing();
      this.$refs.createForm.form.resetFields();
      this.closeModal();
    },
    handleCreate() {
      this.startProcessing();
      this.$refs.createForm.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.organisme) {
            this.update({
              ...values,
              id: this.organisme.id
            })
                    .then(() => {
                      this.cleanModal();
                      this.getAdditioanlData();
                    })
                    .catch(() => this.stopProcessing());
          } else {
            this.add(values)
                    .then(() => {
                      this.cleanModal();
                      this.getAdditioanlData();
                    })
                    .catch(() => this.stopProcessing());
          }
        }
      });
    },
    handleCancel(e) {
      this.closeModal();
    },
    ...mapActions({
      fetchOrganismes: "fetchOrganismes",
      update: "updateOrganisme",
      remove: "destroyOrganisme",
      add: "storeOrganisme",
      getTypeAttestations: "fetchTypeAttestations"
    }),
    ...mapMutations({
      setOrganisme: "setSelectedOrganisme",
      clearOrganisme: "clearSelectedOrganisme"
    })
  }
};
</script>
<style>
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.editable-add-btn {
  margin-bottom: 8px;
}
.action_icons {
  margin: 5px;
  font-size: large;
}
</style>
