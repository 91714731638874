<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
    :confirmLoading="processing"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="add_attestation_form"
      name="add_attestation_form"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="20">
          <a-form-item v-bind="formItemLayout" label="Code">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Code est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Nom">
            <a-input
              v-decorator="[
                'nom_fr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Nom ar">
            <a-input
              v-decorator="[
                'nom_ar',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Nom arabe est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Adresse">
            <a-input
              v-decorator="[
                'adresse',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Adresse  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Tél. mobile">
            <a-input
              v-decorator="[
                'tel_mobile',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Tél.  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Tél. fixe">
            <a-input
              v-decorator="[
                'tel_fix',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Tél.  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Fax">
            <a-input
              v-decorator="[
                'tel_fax',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Fax  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Email">
            <a-input
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Fax  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Type Attestations">
            <a-select
              v-decorator="[
                'type_attestation_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'type attestation est obligatoire!',
                    },
                  ],
                },
              ]"
              placeholder="Choisir un type"
            >
              <a-select-option :key="0">Choisir un type</a-select-option>
              <a-select-option
                v-for="type_attestation in typeAttestations"
                :key="type_attestation.id"
                >{{ type_attestation.nom_fr }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Avantage">
            <a-textarea
              v-decorator="[
                'avantage',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Avantage  est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>
<script>
  import ATextarea from "ant-design-vue/es/input/TextArea";

  export default {
    name: "OrganismeEditor",
    components: {ATextarea},
    created() {
      if (this.editMode) {
        this.fillTheForm();
      }
    },
    data() {
      return {
        formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 12,
            offset: 0,
          },
          sm: {
            span: 12,
            offset: 20,
          },
        },
      },
      form: this.$form.createForm(this),
    };
  },
  props: ["visible", "organisme", "typeAttestations", "processing"],
  computed: {
    editMode() {
      return !!this.organisme;
    },
    title() {
      return this.editMode
        ? `${this.organisme.nom_fr}`
        : `Nouveau organisme`;
    },
  },
  methods: {
    fillTheForm() {
      this.$nextTick(() => {
        this.form.setFieldsValue(this.organisme);
        this.form.setFieldsValue({
          type_attestation_id: this.organisme.type_attestation.id,
        });
      });
    },
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleOk() {
      this.$emit("confirme");
    },
  },
};
</script>
